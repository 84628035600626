import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  MenuItem,
  Paper,
  Popper,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';

// project import
import Avatar from 'components/@extended/Avatar';
import IconButton from 'components/@extended/IconButton';
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';

import useAuth from 'hooks/useAuth';
import { ThemeMode } from 'config';

import * as userApi from 'api/UserApi';

// third-party
import { FormattedMessage, useIntl } from 'react-intl';

// assets
import avatar1 from 'assets/images/users/avatar-1.png';
import { AutorenewOutlined, PowerSettingsNewOutlined } from '@mui/icons-material';

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const intl = useIntl();
  const { loginWithComp, logout, user } = useAuth();

  const [userDetails, setUserDetails] = useState([]);
  useEffect(() => {
    (async () => {
      await userApi.MyAccount().then((response) => {
        setUserDetails(response?.data?.value);
      });
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const companies = JSON.parse(localStorage.getItem('companies'));
  const selectedCompany = localStorage.getItem('company');
  const [company, setCompany] = useState(localStorage.getItem('company'));
  const companySelect = companies?.map((item) => (<MenuItem key={item.compid} value={item.compid}>{item.unvan}</MenuItem>));

  const handleLogout = async () => {
    try {
      await logout();
      navigate(`/login`, {
        state: {
          from: '/'
        }
      });
    } catch (err) {
      console.error('API hatası:', err);
    }
  };

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (event.target.localName === 'body') {
      return;
    }
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleSelect = async () => {
    selectedCompany !== company && await loginWithComp(company).then(() => navigate(0));
  };

  const iconBackColorOpen = theme.palette.mode === ThemeMode.DARK ? 'background.default' : 'grey.100';

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : 'transparent',
          borderRadius: 1,
          '&:hover': { bgcolor: theme.palette.mode === ThemeMode.DARK ? 'secondary.light' : 'secondary.lighter' },
          '&:focus-visible': {
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2
          }
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={1.25} alignItems="center" sx={{ p: 0.5 }}>
          <Avatar alt="company logo" src={avatar1} size="sm" />
          <Typography variant="subtitle1" sx={{ textTransform: 'capitalize' }}>
            {user}
          </Typography>
        </Stack>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="grow" position="top-right" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                // width: 290,
                minWidth: 300,
                maxWidth: 350,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 300
                }
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard elevation={0} border={false} content={false}>
                  <CardContent>
                    <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                      <Grid item xs={2}>
                        <Avatar alt="company logo" src={avatar1} />
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="button" component="h1">{userDetails.name} {userDetails.lastName}</Typography>
                        <Typography variant="subtitle2" component="h2" color="textSecondary">{userDetails.email}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Tooltip title={<FormattedMessage id='logout' />}>
                          <IconButton
                            size="large"
                            sx={{
                              color: 'text.primary',
                              "&:hover": {
                                color: theme.palette.error.dark,
                                backgroundColor: theme.palette.error.lighter
                              }
                            }}
                            onClick={handleLogout}>
                            <PowerSettingsNewOutlined />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                      {companies?.length === 1
                        ? (
                          <Grid item xs={12}>
                            <TextField
                              label={intl.formatMessage({ id: "company" })}
                              fullWidth
                              id="company"
                              defaultValue={companies?.find(comp => comp.compid === selectedCompany)?.unvan}
                              name="company"
                              inputProps={{ readOnly: true }}
                            />
                          </Grid>
                        )
                        : <>
                          <Grid item xs={10}>
                            <TextField
                              label={intl.formatMessage({ id: "company" })}
                              select
                              fullWidth
                              id="company"
                              value={company}
                              name="company"
                              onChange={(event) => setCompany(event.target.value)}
                            >
                              {companySelect}
                            </TextField>
                          </Grid>
                          <Grid item xs={2}>
                            <Tooltip title={<FormattedMessage id='choose-company' />}>
                              <IconButton
                                size="large"
                                sx={{ color: 'text.primary' }}
                                onClick={handleSelect}
                              >
                                <AutorenewOutlined />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </>
                      }
                    </Grid>
                  </CardContent>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box >
  );
};

export default Profile;
