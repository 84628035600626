import { createBrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';

// project import
import MainRoutes from './MainRoutes';
import ReportRoutes from './ReportRoutes';
import LoginRoutes from './LoginRoutes';
import ErrorRoutes from './ErrorRoutes';
import OtherRoutes from './OtherRoutes';

// ==============================|| ROUTING RENDER ||============================== //

const router = Sentry.wrapCreateBrowserRouter(
    createBrowserRouter(
        [
            LoginRoutes, // always must be on top!
            MainRoutes,
            ReportRoutes,
            OtherRoutes,
            ErrorRoutes,
        ]
    )
);

export default router;
