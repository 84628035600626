import { lazy } from 'react';

// project import
import ErrorBoundary from './ErrorBoundary';
import Loadable from 'components/Loadable';
import ProtectedRoutes from 'utils/route-guard/ProtectedRoutes';

import { Loader as ArchiveViewLoader } from 'api/PublicArchiveApi';

// render
const DetailView = Loadable(lazy(() => import('pages/others/show-detail')));
const ArchiveView = Loadable(lazy(() => import('pages/others/show-archive')));
const RedirectTescilLink = Loadable(lazy(() => import('pages/others/redirect-tescil')));
const RedirectArchiveLink = Loadable(lazy(() => import('pages/others/redirect-archive')));
const Tanitim = Loadable(lazy(() => import('pages/others/tanitim')));

// ==============================|| OTHER PAGES ROUTING ||============================== //

const OtherRoutes = {
  path: '/',
  errorElement: <ErrorBoundary />,
  children: [
    {
      path: '/',
      element: <ProtectedRoutes />,
      children: [
        {
          path: '/api/ShowDetail/:referans',
          element: <DetailView />
        }
      ]
    },
    {
      path: '/api/ShowArchive/:beyid/:beyuuid',
      element: <ArchiveView />,
      loader: ({ params }) => {
        const { beyid, beyuuid } = params;
        if (beyid && beyuuid) {
          return ArchiveViewLoader(beyid, beyuuid);
        }
        return null;
      }
    },
    {
      path: '/beyanname/tescil/xml/download/:global_id',
      element: <RedirectTescilLink />
    },
    {
      path: '/api/PublicArchive/:type/:beyid/:beyuuid',
      element: <RedirectArchiveLink />
    },
    {
      path: '/kontratlojistiktanitim',
      element: <Tanitim />
    }
  ]
};

export default OtherRoutes;
