import axios from 'utils/axios';

export const MyAccount = async () => {
    try {
        return await axios.get("/User/MyAccount");
    } catch (error) {
        return Promise.reject(error);
    }
};

export const MailContact = async (data) => {
    try {
        return await axios.post("/User/MailContact",
            data,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    } catch (error) {
        return Promise.reject(error);
    }
};
