import axios from 'utils/axios';

export const Loader = async (beyid, beyuuid) => {
    try {
        const response = await ArchiveShowList(beyid, beyuuid);
        return response.data;
    } catch (error) {
        console.error('API hatası:', error);
        return error;
    }
};

export const DownloadFile = async (rplserver, uniq_id) => {
    try {
        return await axios.get(`/PublicArchive/DownloadFile/${rplserver}/${uniq_id}`,
            {
                responseType: 'blob'
            }
        );
    } catch (error) {
        if (error.status === 200) return error; // downloadable file
        return Promise.reject(error);
    }
};

export const DownloadAll = async (beyid, beyuuid) => {
    try {
        return await axios.get(`/PublicArchive/Download/${beyid}/${beyuuid}`,
            {
                responseType: 'blob'
            }
        );
    } catch (error) {
        if (error.status === 200) return error; // downloadable file
        return Promise.reject(error);
    }
};

export const DownloadInOne = async (beyid, beyuuid) => {
    try {
        return await axios.get(`/PublicArchive/MergeAndZipFiles/${beyid}/${beyuuid}`,
            {
                responseType: 'blob'
            }
        );
    } catch (error) {
        if (error.status === 200) return error; // downloadable file
        return Promise.reject(error);
    }
};

export const DownloadSelected = async (data, rplserver) => {
    try {
        return await axios.post("/PublicArchive/MergePdfsByUniqIds/" + rplserver,
            data,
            {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json-patch+json'
                }
            }
        );
    } catch (error) {
        if (error.status === 200) return error; // downloadable file
        return Promise.reject(error);
    }
};

export const ArchiveShowList = async (beyid, beyuuid) => {
    try {
        return await axios.get(`/PublicArchive/ArchiveShowList/${beyid}/${beyuuid}`);
    } catch (error) {
        if (error.status === 200) return error; // data success dönmüyor
        return Promise.reject(error);
    }
};

export const ArchiveShowDetail = async (rplserver, uniq_id) => {
    try {
        return await axios.get(`/PublicArchive/ArchiveShowDetail/${rplserver}/${uniq_id}`);
    } catch (error) {
        if (error.status === 200) return error; // data success dönmüyor
        return Promise.reject(error);
    }
};
