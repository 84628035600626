import PropTypes from 'prop-types';
import { createContext, useState } from 'react';

// ==============================|| NETWORK CONTEXT & PROVIDER ||============================== //

const NetworkContext = createContext();

function NetworkProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);

  const startLoading = () => setIsLoading(true);
  const stopLoading = () => setIsLoading(false);

  return (
    <NetworkContext.Provider
      value={{
        isLoading,
        startLoading,
        stopLoading
      }}
    >
      {children}
    </NetworkContext.Provider>
  );
}

NetworkProvider.propTypes = {
  children: PropTypes.node
};

export { NetworkProvider, NetworkContext };
