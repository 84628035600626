import axios from 'axios';

import { openSnackbar } from 'api/snackbar';

const axiosServices = axios.create({ baseURL: process.env.REACT_APP_API_URL });

// ==============================|| AXIOS CONFIG ||============================== //

axiosServices.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem('serviceToken');
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }

    const i18n = JSON.parse(localStorage.getItem('config'))?.i18n || 'tr';
    config.headers['Locale'] = i18n;

    // config.headers['Accept-Language'] = "tr-TR";
    // config.headers['Accept'] = "application/json";
    // config.headers['Content-Type'] = "application/json";
    // config.headers['Access-Control-Allow-Origin'] = "*";
    // config.headers['Access-Control-Allow-Credentials'] = "true";
    // config.headers['Access-Control-Allow-Headers'] = "Origin, X-Requested-With, Content-Type, Accept";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosServices.interceptors.response.use(
  (response) => {
    // blob indirme durumlarında data.success gelmiyor
    if (response.data.success === undefined) {
      return response;
    }
    // kalan tüm durumlarda data.success geliyor ve true değilse http 200 ok olduğu halde içinde hata mesajı olabiliyor
    if (!response.data.success || response.status !== 200) {
      openSnackbar({
        open: true,
        close: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          severity: 'error',
          color: 'error'
        }
      });
      return Promise.reject(response);
    }
    return response;
  },
  (error) => {
    // ValidateToken yüzünden sentry'e hata fırlatmak istemiyoruz
    if (error.config.url === "/User/ValidateToken") {
      // localStorage.clear(); // dil seçimi sıfırlanıyor, cache'den eski seçimi getiriyor
      // localStorage'daki tüm anahtarları gez ve sadece "config" dışındaki anahtarları temizle
      Object.keys(localStorage).forEach((key) => {
        if (key !== "config") {
          localStorage.removeItem(key);
        }
      });
      // !window.location.href.includes('/login') && !window.location.href.includes('/ShowArchive') && (window.location.href = '/login');
      return error;
    }
    // Eğer hata blob ise JSON'a dönüştür
    if (error.response && error.response.data instanceof Blob) {
      return error.response.data.text() // Blob'u text olarak al
        .then((errorText) => {
          try {
            const errorJson = JSON.parse(errorText); // JSON'a dönüştür
            openSnackbar({
              open: true,
              close: true,
              message: errorJson?.message || error?.message,
              variant: 'alert',
              alert: {
                severity: 'error',
                color: 'error'
              }
            });
            return Promise.reject(errorJson); // JSON hatasını döndür
          } catch (jsonParseError) {
            return Promise.reject(errorText); // JSON değilse text olarak döndür
          }
        });
    }
    // Eğer response alındıysa ve kod 401 ise ve login sayfasında değil ise toast message bas ve logine yönlendir
    if (error.response?.status === 401 && !window.location.href.includes('/login')) {
      openSnackbar({
        open: true,
        close: true,
        message: error.response.message,
        variant: 'alert',
        alert: {
          severity: 'error',
          color: 'error'
        }
      });
      // localStorage.clear(); // dil seçimi sıfırlanıyor, cache'den eski seçimi getiriyor
      // localStorage'daki tüm anahtarları gez ve sadece "config" dışındaki anahtarları temizle
      Object.keys(localStorage).forEach((key) => {
        if (key !== "config") {
          localStorage.removeItem(key);
        }
      });
      window.location.href = '/login';
    }
    // Eğer response alındıysa ve kod 403 ise login yapmıştır ama yetkisi yoktur anasayfaya yönlendir
    if (error.response?.status === 403) {
      openSnackbar({
        open: true,
        close: true,
        message: error.response.message,
        variant: 'alert',
        alert: {
          severity: 'error',
          color: 'error'
        }
      });
      window.location.href = '/dashboard';
    }
    // if (error.response?.status === 404) {
    //   window.location.href = '/error/404';
    // }
    if (error.response?.status === 500) {
      window.location.href = '/error/500';
    }
    // axios 10 error codes = ERR_FR_TOO_MANY_REDIRECTS, ERR_BAD_OPTION_VALUE, ERR_BAD_OPTION, ERR_NETWORK, ERR_DEPRECATED, ERR_BAD_RESPONSE, ERR_BAD_REQUEST, ERR_CANCELED, ECONNABORTED, ETIMEDOUT
    if (error.code === 'ERR_BAD_REQUEST') {
      openSnackbar({
        open: true,
        close: true,
        message: error?.response?.data?.message || error?.message,
        variant: 'alert',
        alert: {
          severity: 'error',
          color: 'error'
        }
      });
      // return Promise.reject(error?.response?.data || error);
    }
    // Eğer axios ERR_NETWORK atıyorsa VPN bağlı değildir veya response body dönmeyen bir apiye istek atılmıştır
    if (error.code === 'ERR_NETWORK') {
      openSnackbar({
        open: true,
        close: true,
        message: error.message,
        variant: 'alert',
        alert: {
          severity: 'error',
          color: 'error'
        }
      });
      // localStorage.clear(); // dil seçimi sıfırlanıyor, cache'den eski seçimi getiriyor
      // localStorage'daki tüm anahtarları gez ve sadece "config" dışındaki anahtarları temizle
      Object.keys(localStorage).forEach((key) => {
        if (key !== "config") {
          localStorage.removeItem(key);
        }
      });
      window.location.pathname !== '/login' && (window.location.href = '/login');
      // return Promise.reject(error);
    }
    return Promise.reject((error.response && error.response.data) || error);
  }
);

export default axiosServices;

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosServices.get(url, { ...config });

  return res.data;
};

export const fetcherPost = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosServices.post(url, { ...config });

  return res.data;
};
