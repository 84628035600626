import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
// import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'contexts/auth-reducer/actions';
import authReducer from 'contexts/auth-reducer/auth';

// project import
import Loader from 'components/Loader';
import axios from 'utils/axios';

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

// prod'da kriptolanmış token geliyor ve exp okunamıyor
// const verifyToken = (serviceToken) => {
//   if (!serviceToken) {
//     return false;
//   }
//   const decoded = jwtDecode(serviceToken);
//   /**
//    * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
//    */
//   return decoded.exp > Date.now() / 1000;
// };

const verifyToken = async () => {
  try {
    const response = await axios.get('/User/ValidateToken');
    if (response.status === 200) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const setCompany = (company) => {
  if (company) {
    localStorage.setItem('company', company);
  } else {
    localStorage.removeItem('company');
  }
};

const setCompanies = (companies) => {
  if (companies) {
    localStorage.setItem('companies', companies);
  } else {
    localStorage.removeItem('companies');
  }
};

const setUser = (user) => {
  if (user) {
    localStorage.setItem('user', user);
  } else {
    localStorage.removeItem('user');
  }
};

const setAuth = (auth) => {
  if (auth) {
    localStorage.setItem('authenticated', auth);
  } else {
    localStorage.removeItem('authenticated');
  }
};

const getUser = () => localStorage.getItem('user');

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        const isAuthenticated = window.localStorage.getItem('authenticated') === 'true';
        const serviceToken = window.localStorage.getItem('serviceToken');
        const user = window.localStorage.getItem('user');
        if (isAuthenticated && serviceToken
          // && verifyToken(serviceToken)
          && await verifyToken()
        ) {
          setSession(serviceToken);
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user
            }
          });
        } else {
          dispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        console.error('API hatası:', err);
        dispatch({
          type: LOGOUT
        });
      }
    };

    init();
  }, []);

  const login = async (username, password) => {
    const response = await axios.post('/User/NewLogin', { userName: username, password: password });
    const serviceToken = response?.data?.value?.apiToken; // LoginWithComp için token
    const user = response?.data?.value?.userName;
    const companies = JSON.stringify(response?.data?.value?.ilgarUser?.part);
    setSession(serviceToken);
    setCompanies(companies);
    setUser(user);
    // dispatch({
    //   type: LOGIN,
    //   payload: {
    //     isLoggedIn: true,
    //     user
    //   }
    // });

    return response;
  };

  const loginWithComp = async (id) => {
    const response = await axios.get('/User/LoginWithComp/' + id);
    const serviceToken = response?.data?.value; // kalan tüm işlemler için token
    setSession(serviceToken);
    setCompany(id);
    setAuth('true');

    const user = getUser();
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user
      }
    });

    return response;
  };

  const logout = () => {
    setSession(null);
    setUser(null);
    setCompany(null);
    setCompanies(null);
    setAuth(null);
    // localStorage.clear(); // dil seçimi sıfırlanıyor, cache'den eski seçimi getiriyor
    // localStorage'daki tüm anahtarları gez ve sadece "config" dışındaki anahtarları temizle
    Object.keys(localStorage).forEach((key) => {
      if (key !== "config") {
        localStorage.removeItem(key);
      }
    });
    dispatch({ type: LOGOUT });
  };

  const resetPassword = async () => { };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return <JWTContext.Provider value={{ ...state, login, loginWithComp, logout, resetPassword }}>{children}</JWTContext.Provider>;
};

JWTProvider.propTypes = {
  children: PropTypes.node
};

export default JWTContext;
