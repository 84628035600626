import { useLayoutEffect, useState } from 'react';

// material-ui
import { Box, Typography } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import MenuFromAPI, { helpMenu } from 'menu-items/dynamic';

import { useGetMenu, useGetMenuMaster } from 'api/menu';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const { menuLoading } = useGetMenu();
  const { menuMaster } = useGetMenuMaster();
  const drawerOpen = menuMaster.isDashboardDrawerOpened;

  const [selectedItems, setSelectedItems] = useState('');
  const [selectedLevel, setSelectedLevel] = useState(0);
  const [menuItems, setMenuItems] = useState({ items: [] });

  let dashboardMenu = MenuFromAPI();

  useLayoutEffect(() => {
    // setMenuItems({ items: [...Object.values(dashboardMenu)] });
    setMenuItems({ items: [...menuItem.items, ...Object.values(dashboardMenu), helpMenu] });
    // setMenuItems({ items: [...menuItem.items, ...Object.values(dashboardMenu)] });

    // eslint-disable-next-line
  }, [menuLoading]);

  const navGroups = menuItems.items.map((item) => {
    switch (item.type) {
      case 'group':
        return (
          <NavGroup
            key={item.id}
            item={item}
            setSelectedItems={setSelectedItems}
            setSelectedLevel={setSelectedLevel}
            selectedLevel={selectedLevel}
            selectedItems={selectedItems}
          />
        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return (
    <Box
      sx={{
        pt: drawerOpen ? 2 : 0,
        '& > ul:first-of-type': { mt: 0 },
        display: 'block'
      }}
    >
      {navGroups}
    </Box>
  );
};

export default Navigation;
