import axios from 'utils/axios';

export const Loader = async (topMenuId) => {
    try {
        const response = await GetComponents(topMenuId);
        return response?.data?.value;
    } catch (error) {
        console.error('API hatası:', error);
        return error;
    }
};

export const GetComponents = async (slugName) => {
    try {
        return await axios.get("/Component/GetComponentsV2/" + slugName);
    } catch (error) {
        return Promise.reject(error);
    }
};
