import { RouterProvider } from 'react-router-dom';

// project import
import router from 'routes';
import ThemeCustomization from 'themes';

import Locales from 'components/Locales';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';

// auth provider
import { JWTProvider as AuthProvider } from './contexts/JWTContext';
import { NetworkProvider } from 'contexts/NetworkContext';

// ==============================|| APP - THEME, ROUTER, LOCALE ||============================== //

const App = () => (
  <ThemeCustomization>
    <Locales>
      <ScrollTop>
        <NetworkProvider>
          <AuthProvider>
            <Notistack>
              <RouterProvider router={router} />
              <Snackbar />
            </Notistack>
          </AuthProvider>
        </NetworkProvider>
      </ScrollTop>
    </Locales>
  </ThemeCustomization>
);

export default App;
