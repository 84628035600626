// project import
import dashboard from './dashboard';
// import reports from './reports';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard]
};

export default menuItems;
