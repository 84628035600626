import { lazy } from 'react';

// project import
import DashboardLayout from 'layout/Dashboard';
import ErrorBoundary from './ErrorBoundary';
import Loadable from 'components/Loadable';

import { Loader as reportListLoader } from 'api/ComponentApi';

// render
const ReportCards = Loadable(lazy(() => import('pages/reports/report-cards')));
const GridReport = Loadable(lazy(() => import('pages/reports/grid-report')));
const Archive = Loadable(lazy(() => import('pages/reports/archive')));

// ==============================|| REPORTS ROUTING ||============================== //

const ReportRoutes = {
  path: '/',
  element: <DashboardLayout />,
  errorElement: <ErrorBoundary />,
  children: [
    {
      path: '/analiz-raporlari/:slugName',
      element: <ReportCards />,
      loader: ({ params }) => {
        const { slugName } = params;
        if (slugName) {
          return reportListLoader(slugName);
        }
        return null;
      }
    },
    {
      path: '/grid-raporlari/:slugName',
      element: <ReportCards />,
      loader: ({ params }) => {
        const { slugName } = params;
        if (slugName) {
          return reportListLoader(slugName);
        }
        return null;
      }
    },
    {
      path: '/rapor/:componentId',
      element: <GridReport />
    },
    {
      path: '/arsiv/arsiv-verileri',
      element: <Archive />
    }
  ]
};

export default ReportRoutes;
