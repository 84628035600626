import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project import
import AuthLayout from 'layout/Auth';
import ErrorBoundary from './ErrorBoundary';
import Loadable from 'components/Loadable';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/forgot-password')));
const AuthCheckMail = Loadable(lazy(() => import('pages/auth/check-mail')));
// const AuthResetPassword = Loadable(lazy(() => import('pages/auth/reset-password')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  element: <AuthLayout />,
  errorElement: <ErrorBoundary />,
  children: [
    {
      path: '/',
      element: <Navigate to="/login" state={{ from: '/' }} />
    },
    {
      path: 'login',
      element: <AuthLogin />
    },
    {
      path: 'forgot-password',
      element: <AuthForgotPassword />
    },
    {
      path: 'check-mail',
      element: <AuthCheckMail />
    },
    // {
    //   path: 'reset-password',
    //   element: <AuthResetPassword />
    // },
    {
      path: '*',
      element: <Navigate to="/error/404" />
    }
  ]
};

export default LoginRoutes;
