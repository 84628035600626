// third-party
import { FormattedMessage } from 'react-intl';

// project import
import { useGetMenu } from 'api/menu';

// assets
import { LoadingOutlined, PhoneTwoTone } from '@ant-design/icons';

const icons = {
    loading: LoadingOutlined,
    PhoneTwoTone
};

const loadingMenu = {
    "dashboard": {
        id: 'group-dashboard-loading',
        title: <FormattedMessage id="menus" />,
        type: 'group',
        icon: icons.loading,
        children: [
            {
                id: 'collapse1',
                title: <FormattedMessage id="loading" />,
                type: 'collapse',
                icon: icons.loading,
                // children: [
                //     {
                //         id: 'item1',
                //         title: <FormattedMessage id="loading" />,
                //         type: 'item',
                //         url: null,
                //         breadcrumbs: false
                //     }
                // ]
            }
        ]
    }
};

const errorMenu = {
    "dashboard": {
        id: 'group-error',
        title: '',
        type: 'group',
        children: [
            {
                id: 'error1',
                title: <FormattedMessage id="401-alert" />,
                type: 'item',
                url: '/',
                icon: icons.loading
            }
        ]
    }
};

export const helpMenu = {
    id: 'group-help',
    title: '', // <FormattedMessage id="help" />,
    type: 'group',
    children: [
        {
            id: 'contact-us',
            title: <FormattedMessage id="contact-us" />,
            type: 'item',
            url: '/contact-us',
            icon: icons.PhoneTwoTone

        }
    ]
};

// ==============================|| MENU ITEMS - API ||============================== //

const MenuFromAPI = () => {
    const { menu, menuLoading } = useGetMenu();

    if (menuLoading) return loadingMenu;
    return menu || errorMenu;
};

export default MenuFromAPI;
