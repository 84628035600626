// material-ui
import { Box, Tooltip } from '@mui/material';

// project import
import IconButton from 'components/@extended/IconButton';
import { ThemeMode } from 'config';
import useConfig from 'hooks/useConfig';

// assets
import { MoonOutlined, SunOutlined } from '@ant-design/icons';

// import 'devextreme/dist/css/dx.light.css';
// import 'devextreme/dist/css/dx.dark.css';
import themes from 'devextreme/ui/themes';

// third-party
import { FormattedMessage } from 'react-intl';

// ==============================|| HEADER CONTENT - DARK MODE ||============================== //

const DarkMode = () => {
  const { mode, onChangeMode } = useConfig();

  const handleModeChange = () => {
    if (mode === ThemeMode.LIGHT) {
      onChangeMode(ThemeMode.DARK);
      themes.current("generic.dark");
    } else {
      onChangeMode(ThemeMode.LIGHT);
      themes.current("generic.light");
    }
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <Tooltip title={mode === ThemeMode.LIGHT ? <FormattedMessage id='dark-mode' /> : <FormattedMessage id='light-mode' />}>
        <IconButton
          color="secondary"
          variant="light"
          sx={{ color: 'text.primary', bgcolor: 'transparent' }}
          aria-label="dark mode toggler"
          onClick={handleModeChange}
        >
          {mode === ThemeMode.LIGHT ? <MoonOutlined /> : <SunOutlined />}
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default DarkMode;
